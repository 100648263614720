import { BrowserRouter } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import "../src/css/common.css";
// routes
import Router from "./routes";
// theme
import ThemeProvider from "./theme";
// components
import { StyledChart } from "./components/chart";
import ScrollToTop from "./components/scroll-to-top";
import { useEffect, useState, Suspense } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

import Loading from './Loading';

import { DarkModeProvider } from './components/context/DarkModeContext';

export default function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(
    sessionStorage.getItem("isAuthenticated") || ""
  );

  useEffect(() => {
    if (!sessionStorage.getItem("isAuthenticated")) {
      sessionStorage.setItem("isAuthenticated", isAuthenticated);
    }
  }, [isAuthenticated]);
  const handleLogin = (data) => {
    setIsAuthenticated(data);
    sessionStorage.setItem("isAuthenticated", data);
  };

  const handleLogout = () => {
    setIsAuthenticated(false);
    sessionStorage.clear();
  };

  return (
    <DarkModeProvider>
      <HelmetProvider>
        <BrowserRouter>
          <ThemeProvider>
            <ScrollToTop />
            <StyledChart />
            <Suspense fallback={<Loading />}>
              <Router
                isAuthenticated={isAuthenticated}
                onLogin={handleLogin}
                onLogout={handleLogout}
              />
            </Suspense>
          </ThemeProvider>
        </BrowserRouter>
      </HelmetProvider>
    </DarkModeProvider>
  );
}
