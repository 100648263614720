import React from 'react';

import { Navigate, useRoutes } from "react-router-dom";
import { useSelector } from 'react-redux'
// layouts
const DashboardLayout = React.lazy(() => import('./layouts/dashboard'));
const SimpleLayout = React.lazy(() => import('./layouts/simple'));
// pages
const ResetPasswordPage = React.lazy(() => import('./pages/ResetPassword'));
const LoginPage = React.lazy(() => import('./pages/LoginPage'));
const Page404 = React.lazy(() => import('./pages/Page404'));
const DashboardAppPage = React.lazy(() => import('./pages/DashboardAppPage'));
const Product = React.lazy(() => import('./pages/Product/product'));
const Company = React.lazy(() => import('./pages/Company/company'));
const Admin = React.lazy(() => import('./pages/Admin/Admin'));
const AdminList = React.lazy(() => import('./pages/Admin/AdminList'));
const AllUsersReferrers = React.lazy(() => import('./pages/AllUsersReferrers/allUsersReferrers'));
const Customers = React.lazy(() => import('./pages/Customers/customers'));
const Transactions = React.lazy(() => import('./pages/Transaction/transaction'));
const Teams = React.lazy(() => import('./pages/Teams/teams'));
const Env = React.lazy(() => import('./pages/env/env'));

export default function Router({ isAuthenticated, onLogin, onLogout }) {
  console.log("isAuthenticated:", isAuthenticated);

  const routes = useRoutes([
    {
      path: "/admin",
      element:
        isAuthenticated === "Super-Admin" ? (
          <DashboardLayout onLogout={onLogout} />
        ) : (
          <Navigate to="/login" />
        ),
      children: [
        { path: "dashboard", element: <DashboardAppPage /> },
        { path: "product", element: <Product /> },
        { path: "company", element: <Company /> },
        { path: "profile", element: <Admin /> },
        { path: "admins", element: <AdminList /> },
        { path: "customers", element: <Customers /> },
        { path: "allUsersReferrers", element: <AllUsersReferrers /> },
        { path: "referrers", element: <Teams /> },
        { path: "transactions", element: <Transactions /> },
        { path: "server", element: <Env /> },
      ],
    },
    {
      path: "/login",
      element:
        isAuthenticated === "Super-Admin" ? (
          <Navigate to="/admin/dashboard" />
        ) : (
          <LoginPage onLogin={onLogin} />
        ),
    },
    {
      path: "/password/reset",
      element: <ResetPasswordPage />,
    },
    {
      element: <SimpleLayout />,
      children: [
        {
          element: (
            <Navigate
              to={
                isAuthenticated === "Super-Admin"
                  ? "/admin/dashboard"
                  : "/login"
              }
            />
          ),
          index: true,
        },
        { path: "404", element: <Page404 /> },
        { path: "*", element: <Navigate to="/404" /> },
      ],
    },
    {
      path: "*",
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
